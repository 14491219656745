import React from 'react';
import Layout from 'components/layout';
import TermsPage from 'paths/terms_page';
import FsAnalytics from 'components/fs_analytics';

const Terms = () => {
    return (
        <Layout>
            {/* Finstead Analytics */}
            <FsAnalytics />
            <TermsPage/>
        </Layout>
    )
}
export default Terms;